import React, { useEffect } from 'react'
import Tags from './Tag';
import Aos from 'aos'
import 'aos/dist/aos.css'

function Card(props){
   
    useEffect(() => {
        Aos.init({duration: 2000})
    },[]);

    return(
        //change max with back to 350px
        <div className = ' flex flex-col border-4 border-black text-gray-300 max-w-[350px] min-h-[10rem] mt-[0px] pb-4 shadow-lg shadow-black sm:hover:scale-110 sm:duration-500'>
           <div className='p-4 border-b-4 border-black mb-4 flex justify-center'>
            <img
                    className = " max-h-[200px] max-w-[200px]"
                    src = {props.image}
                    alt = ''
                />
           </div>

            
            <div className = 'px-4 h-full '>
                <div className = 'flex flex-wrap justify-start gap-2 pb-2'>
                    {props.tags?.length > 0 && props.tags.map((props,index) =>(
                        <Tags name = {props.name} logo = {props.logo}/>
                    ))}
                </div>
                <h2 className = "font-bold text-2xl mt-2" >{props.title}</h2>
                <p className="font-medium text-base text-" style={{ whiteSpace: 'pre-line' }}>
                  {props.text}
                </p>
                </div>
            
            
            <div className = "mt-4 mx-4 flex flex-row gap-4">        
                {props.b1 ? 
                    <a className="mb-0 container" href={props.gitrepo} ><button className="mb-0 container bg-blue-400 hover:bg-blue-400/60 px-6 py-2 font-medium text-white">Git Repo</button></a>
                :''}
                {props.b2 ? 
                    <a className="mb-0 container" href={props.now} ><button className="mb-0 container bg-blue-400 hover:bg-blue-400/60 px-6 py-2 font-medium text-white">Try Now!</button></a>
                :''}

                {!props.b1 && !props.b2  && !props.b3? 
                     <a className="mb-0 container pointer-events-none"><button className="mb-0 container bg-gray-400 hover:bg-blue-400/60 px-6 py-2 font-medium text-white">In Progress</button></a>
                :''}
            
            </div>
        
        </div>
        
    )



}

export default Card;