import React, { useEffect } from 'react'
import Card from './Card';
import Title from './Title';
import Meeting from '../assets/meeting.jpeg'
import CRUD from '../assets/crud.png'
import WebApi from '../assets/webapi.png'

import Aos from 'aos'
import 'aos/dist/aos.css'

function Artifacts(props){

    useEffect(() => {
        document.title = 'Projects | Aarin Jasikumar';
    }, []);

    useEffect(() => {
        Aos.init({duration: 2000})
    },[]);

    return(
        <div className = 'w-full h-full bg-[#212121] text-gray-300 min-h-[900px]'>
            <div className = 'max-w-[1200px] mx-auto px-8 h-full pb-[100px]'> 
            <Title title={props.title.toUpperCase()}/>
                <div data-aos='fade-left' className = 'flex flex-wrap justify-center gap-8'>
                <Card 
                        image = {WebApi}
                        title = 'Balance Minimum Web API'
                        text = {`
                        Date: Sept 2nd 2024
                        Produced For: Dayforce Monolith

                        Context:
                        The goal of this artifact was to develop a functionality within our software to pull data from our Dayforce Monolith Database and into our microservice. This feature was developed to be used in a much larger feature on our microservice to allow employers to set minimum balances for vacation, time off and other balances. My role involved researching existing web apis within our codebase to understand prior implementations, while also consulting with co-workers for help.

                        Development Details:
                        I developed the web api by utilizing our existing codebase and resources. However due to the current design of how Balances on the monolith were queried I wasn't able to create an instance of this class directly. Instead I had to make a controller and interface to act as an adapter in order to make this work. The skills I applied during this process included critical thinking, system design, and programming.

                        Outcome:
                        The result was a functional web api that was tested and can be used on our microservice to pull balance and balance type data from our monolith code. The reason we did this instead of storing the data into our microservice was because if this data changed within the dayforce platform, it would be reflected in the microservice, ensuring consistency. The feedback I received included suggestions to edit the original balance code on the monolith instead of my adapter.

                        Reflection:
                        This experience highlighted the importance of adaptability and leveraging existing resources to solve complex problems. By researching prior implementations and collaborating with colleagues, I was able to design a functional web API that bridges the monolith and microservice effectively. This experience allowed me to further develop my skills in software validation, modular design, and collaboration skills.
                        `}
                        tags={[]}
                        gitrepo = 'https://github.com/Aarin06/CSCB07_Project'
                        now = ''
                        b1 = {false}
                        b2 = {false}
                        b3 = {true}
  
                   
                    />
                <Card 
                        image = {Meeting}
                        title = 'Generic Component Documentation and Confluence Page'
                        text = {`
                        Date: Oct 8th 2024
                        Produced For: Dayforce PS HR Development Team

                        Context:
                        Myself and another developer recommended creating a generic component for our frontend to significantly reduce the amount of repetitive code in our codebase. I decided to lead the meeting with the entire team to identify what generic components to create and which pages within the application can use the generic component.

                        Development Details:
                        First to prepare for the meeting I went through all of the front-end jira tickets that involved modernizing our UI and collected data of all the different tables, forms, side panels etc. that I needed to support. Then during the meeting I created a Table to organize the different generic components to create as well as which pages it would be used on. We then as a team fixed any of my mistakes that I may have made, organized the components based on priority so we can start grooming them and develop them as soon as possible.

                        Outcome:
                        A confluence page that included details of all the generic components we need to make, the order in which we need to complete them based on priority and dependencies and finally the pages in which they are needed. With this we also made a new Jira Epic and tickets as well to tackle these items.

                        Reflection:
                        Leading this initiative taught me the importance of thorough preparation and team collaboration in driving impactful improvements. By analyzing front-end Jira tickets and facilitating a structured discussion, I was able to guide the team in identifying and prioritizing generic components to streamline our UI.
                        `}
                        tags={[]}
                        gitrepo = 'https://github.com/Aarin06/CSCB07_Project'
                        now = ''
                        b1 = {false}
                        b2 = {false}
                        b3 = {true}
  
                   
                    />
                    <Card 
                        image = {CRUD}
                        title = 'Balance Minimum Full-Stack Feature'
                        text={`
                        Date: Nov 12th 2024
                        Produced For: Dayforce Position Classification Microservice

                        Context:
                        The goal of this task was to implement a feature on our microservice that allows users to set balance minimums based on a specific class. Users can group balances together and set minimums for these groups as well. This functionality was designed to enhance user control over balance management. My role involved leveraging the previously developed web API and ensuring seamless integration with new front-end and back-end components.
                        
                        Development Details:
                        I utilized the web API to fetch balance data and implemented a validation framework to ensure input integrity. On the backend, I developed CRUD operations to support the creation, editing, and deletion of balance minimums and groups. For the frontend, I created a table and side panel interface to allow users to manage these features efficiently. This task encountered delays due to frequent design changes from the product team and unforeseen development issues, which required me to adapt the implementation dynamically.
                        
                        Outcome:
                        The result was a fully functional feature that enables users to set and manage balance minimums with ease. The frontend and backend components worked together seamlessly, offering a smooth user experience while ensuring robust validation. This feature adds significant value to the platform by giving users greater flexibility in balance management and aligns with the overall system design goals.
                        
                        Reflection:
                        Developing the balance minimum feature underscored the value of flexibility and problem-solving in the face of evolving requirements. By building on the web API and implementing a robust validation framework, CRUD operations, and a user-friendly front-end interface, I was able to deliver a comprehensive solution despite design changes and unforeseen challenges. This experience enhanced my skills in full-stack development, validation frameworks, and managing shifting project dynamics while ensuring alignment with user needs.
                        `}
                        tags={[]}
                        gitrepo = 'https://github.com/Aarin06/CSCB07_Project'
                        now = ''
                        b1 = {false}
                        b2 = {false}
                        b3 = {true}
  
                   
                    />
                    

                    
                    
                </div>

            </div> 
            
        </div>

    )



}

export default Artifacts;